import React, { lazy } from "react";
import "./About.scss";
import aboutPageImg from "../../Assets/Red_Theme/aboutPageImg.svg";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import mission from "../../Assets/Red_Theme/mission.svg";
import vision from "../../Assets/Red_Theme/vision.svg";
import bigDot from "../../Assets/Red_Theme/bigDot.svg";
import growWithUs from "../../Assets/Red_Theme/growWithUs.svg";
import dot from "../../Assets/Red_Theme/dot.svg";
import commitment from "../../Assets/Red_Theme/commitment.svg";
import innovation from "../../Assets/Red_Theme/innovation.svg";
import honesty from "../../Assets/Red_Theme/honesty.svg";
import passion from "../../Assets/Red_Theme/passion.svg";
import rightArrow from "../../Assets/Red_Theme/rightArrow.svg";
import galleryvector from "../../Assets/Red_Theme/gallery_vector.svg";

const Banner = ({ portfolio, speed }) => {
  return (
    <div className="inner">
      <div className="wrapper">
        <section className="slide" style={{ "--speed": `${speed}ms` }}>
          {portfolio?.map((image, id) => (
            <div className="image" key={id}>
              <div className="left">
                <img
                  src={image?.galimage?.image}
                  alt={id}
                  className="img-fluid gall"
                />
                <h1>
                  {image?.galimage?.name}
                  {/* <a className="theme_btn"><span>Explore More <img src={rightArrow} alt="" /></span></a> */}
                </h1>
              </div>
              <div className="right">
                <img
                  src={image?.galimage?.thumbnail1}
                  alt={id}
                  className="img-fluid gall"
                />
                <img
                  src={image?.galimage?.thumbnail2}
                  alt={id}
                  className="img-fluid gall"
                />
              </div>
            </div>
          ))}
        </section>
        <section className="slide" style={{ "--speed": `${speed}ms` }}>
          {portfolio?.map((image, id) => (
            <div className="image" key={id}>
              <div className="left">
                <img
                  src={image?.galimage?.image}
                  alt={id}
                  className="img-fluid gall"
                />
                <h1>
                  {image?.galimage?.name}
                  {/* <a className="theme_btn">
                    <span>
                      Explore More <img src={rightArrow} alt="" />
                    </span>
                  </a> */}
                </h1>
              </div>
              <div className="right">
                <img
                  src={image?.galimage?.thumbnail1}
                  alt={id}
                  className="img-fluid gall"
                />
                <img
                  src={image?.galimage?.thumbnail2}
                  alt={id}
                  className="img-fluid gall"
                />
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};
const portfolio = [
  {
    id: 1,
    image: require("../../Assets/Images/Gallery/CompanyAnniversary1.webp"),
    thumbnail1: require("../../Assets/Images/Gallery/5-work-anniverrsary-img1.jpeg"),
    thumbnail2: require("../../Assets/Images/Gallery/5-work-anniverrsary-img2.jpeg"),
    name: "Company Anniversary",
    category: "100arc",
  },
  {
    id: 2,
    image: require("../../Assets/Images/Gallery/holi.webp"),
    thumbnail1: require("../../Assets/Images/Gallery/holi1.webp"),
    thumbnail2: require("../../Assets/Images/Gallery/holi2.webp"),
    name: "Holi",
    category: "holi",
  },
  {
    id: 3,
    image: require("../../Assets/Images/Gallery/birthday/birthday.jpg"),
    thumbnail1: require("../../Assets/Images/Gallery/birthday/birthday1.jpg"),
    thumbnail2: require("../../Assets/Images/Gallery/birthday2.jpg"),
    name: "Birthday",
    category: "birthday",
  },
  {
    id: 4,
    image: require("../../Assets/Images/Gallery/Diwali/diwali1.webp"),
    thumbnail1: require("../../Assets/Images/Gallery/Diwali/diwali2.webp"),
    thumbnail2: require("../../Assets/Images/Gallery/Diwali/diwali3.webp"),
    name: "Diwali",
    category: "diwali",
  },
].map((galimage) => ({
  id: generateId(16),
  galimage,
}));
const portfolio1 = [
  {
    id: 1,
    image: require("../../Assets/Images/Gallery/christmas1.webp"),
    thumbnail1: require("../../Assets/Images/Gallery/christmas2.webp"),
    thumbnail2: require("../../Assets/Images/Gallery/christmas3.webp"),
    name: "Christmas",
    category: "christmas",
  },
  {
    id: 2,
    image: require("../../Assets/Images/Gallery/FunFriday/funfriday4.jpg"),
    thumbnail1: require("../../Assets/Images/Gallery/FunFriday/funfriday1.jpg"),
    thumbnail2: require("../../Assets/Images/Gallery/FunFriday/funfriday2.webp"),
    name: "Fun Friday",
    category: "funFriday",
  },
  {
    id: 3,
    image: require("../../Assets/Images/Gallery/projectsuccess1.webp"),
    thumbnail1: require("../../Assets/Images/Gallery/projectsuccess2.webp"),
    thumbnail2: require("../../Assets/Images/Gallery/projectsuccess3.webp"),
    name: "Project Success Party",
    category: "projectSuccess",
  },
  {
    id: 4,
    image: require("../../Assets/Images/Gallery/Diwali/diwali1.webp"),
    thumbnail1: require("../../Assets/Images/Gallery/Diwali/diwali2.webp"),
    thumbnail2: require("../../Assets/Images/Gallery/Diwali/diwali3.webp"),
    name: "Diwali",
    category: "diwali",
  },
].map((galimage) => ({
  id: generateId(16),
  galimage,
}));
function generateId(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}
function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}
const IMAGES = [
  {
    src: require("../../Assets/Images/Gallery/projectsuccess1.webp"),
    thumbnail: require("../../Assets/Images/Gallery/projectsuccess1.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "projectSuccess",
  },
  {
    src: require("../../Assets/Images/Gallery/projectsuccess2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/projectsuccess2.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "projectSuccess",
  },
  {
    src: require("../../Assets/Images/Gallery/projectsuccess3.webp"),
    thumbnail: require("../../Assets/Images/Gallery/projectsuccess3.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "projectSuccess",
  },
  {
    src: require("../../Assets/Images/Gallery/projectsuccess4.webp"),
    thumbnail: require("../../Assets/Images/Gallery/projectsuccess4.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "projectSuccess",
  },
  {
    src: require("../../Assets/Images/Gallery/projectsuccess5.webp"),
    thumbnail: require("../../Assets/Images/Gallery/projectsuccess5.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "projectSuccess",
  },
  {
    src: require("../../Assets/Images/Gallery/projectsuccess6.webp"),
    thumbnail: require("../../Assets/Images/Gallery/projectsuccess6.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "projectSuccess",
    tit: true,
  },
  {
    src: require("../../Assets/Images/Gallery/FunFriday/funfriday.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/FunFriday/funfriday.jpg"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "funFriday",
  },
  {
    src: require("../../Assets/Images/Gallery/FunFriday/funfriday1.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/FunFriday/funfriday1.jpg"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "funFriday",
  },
  {
    src: require("../../Assets/Images/Gallery/FunFriday/funfriday2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/FunFriday/funfriday2.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "funFriday",
  },
  {
    src: require("../../Assets/Images/Gallery/FunFriday/funfriday4.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/FunFriday/funfriday4.jpg"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "funFriday",
  },
  {
    src: require("../../Assets/Images/Gallery/FunFriday/funfriday5.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/FunFriday/funfriday5.jpg"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "funFriday",
  },
  {
    src: require("../../Assets/Images/Gallery/FunFriday/funfriday8.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/FunFriday/funfriday8.jpg"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "funFriday",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure1.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure1.webp"),
    thumbnailWidth: 150,
    thumbnailHeight: 190,
    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure2.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure3.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure3.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure4.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure4.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure5.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure5.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure6.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure6.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure7.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure7.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/infastructure10.webp"),
    thumbnail: require("../../Assets/Images/Gallery/infastructure8.webp"),
    thumbnailWidth: 271,
    thumbnailHeight: 120,

    caption: "",
    category: "office",
  },
  {
    src: require("../../Assets/Images/Gallery/5-work-anniverrsary-img1.jpeg"),
    thumbnail: require("../../Assets/Images/Gallery/5-work-anniverrsary-img1.jpeg"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/5-work-anniverrsary-img2.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/5-work-anniverrsary-img2.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/5-work-anniverrsary-img3.jpeg"),
    thumbnail: require("../../Assets/Images/Gallery/5-work-anniverrsary-img3.jpeg"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/5-work-anniverrsary-img4.jpeg"),
    thumbnail: require("../../Assets/Images/Gallery/5-work-anniverrsary-img4.jpeg"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/5-work-anniverrsary-img5.jpeg"),
    thumbnail: require("../../Assets/Images/Gallery/5-work-anniverrsary-img5.jpeg"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/5-work-anniverrsary-img6.jpeg"),
    thumbnail: require("../../Assets/Images/Gallery/5-work-anniverrsary-img6.jpeg"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/CompanyAnniversary1.webp"),
    thumbnail: require("../../Assets/Images/Gallery/CompanyAnniversary1.webp"),
    thumbnailWidth: 271,
    thumbnailHeight: 340,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/CompanyAnniversary2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/CompanyAnniversary2.webp"),
    thumbnailWidth: 250,
    thumbnailHeight: 180,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/CompanyAnniversary3.webp"),
    thumbnail: require("../../Assets/Images/Gallery/CompanyAnniversary3.webp"),
    thumbnailWidth: 140,
    thumbnailHeight: 180,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/CompanyAnniversary4.webp"),
    thumbnail: require("../../Assets/Images/Gallery/CompanyAnniversary4.webp"),
    thumbnailWidth: 227,
    thumbnailHeight: 180,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/CompanyAnniversary5.webp"),
    thumbnail: require("../../Assets/Images/Gallery/CompanyAnniversary5.webp"),
    thumbnailWidth: 240,
    thumbnailHeight: 180,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/CompanyAnniversary6.webp"),
    thumbnail: require("../../Assets/Images/Gallery/CompanyAnniversary6.webp"),
    thumbnailWidth: 320,
    thumbnailHeight: 212,

    caption: "",
    category: "100arc",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali1.webp"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali1.webp"),
    thumbnailWidth: 250,
    thumbnailHeight: 180,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali2.webp"),
    thumbnailWidth: 250,
    thumbnailHeight: 180,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali3.webp"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali3.webp"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali4.webp"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali4.webp"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali5.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali5.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali6.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali6.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali7.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali7.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali8.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali8.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali9.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali9.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali10.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali10.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali11.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali11.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali12.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali12.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/Diwali/diwali13.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/Diwali/diwali13.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 120,

    caption: "",
    category: "diwali",
  },
  {
    src: require("../../Assets/Images/Gallery/holi.webp"),
    thumbnail: require("../../Assets/Images/Gallery/holi.webp"),
    thumbnailWidth: 250,
    thumbnailHeight: 190,

    caption: "",
    category: "holi",
  },
  {
    src: require("../../Assets/Images/Gallery/holi1.webp"),
    thumbnail: require("../../Assets/Images/Gallery/holi1.webp"),
    thumbnailWidth: 140,
    thumbnailHeight: 180,

    caption: "",
    category: "holi",
  },
  {
    src: require("../../Assets/Images/Gallery/holi2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/holi2.webp"),
    thumbnailWidth: 230,
    thumbnailHeight: 180,

    caption: "",
    category: "holi",
  },
  {
    src: require("./../../Assets/Images/Gallery/birthday/birthday.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/birthday/birthday.jpg"),
    thumbnailWidth: 270,
    thumbnailHeight: 210,
    caption: "",
    category: "birthday",
  },
  {
    src: require("../../Assets/Images/Gallery/birthday/birthday1.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/birthday/birthday1thumb.jpg"),
    thumbnailWidth: 130,
    thumbnailHeight: 180,

    caption: "",
    category: "birthday",
  },
  {
    src: require("../../Assets/Images/Gallery/birthday2.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/birthday2thumb.jpg"),
    thumbnailWidth: 230,
    thumbnailHeight: 180,

    caption: "",
    category: "birthday",
  },
  {
    src: require("../../Assets/Images/Gallery/birthday3.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/birthday3.jpg"),
    thumbnailWidth: 230,
    thumbnailHeight: 180,

    caption: "",
    category: "birthday",
  },
  {
    src: require("../../Assets/Images/Gallery/birthday/birthday4.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/birthday/birthday4.jpg"),
    thumbnailWidth: 230,
    thumbnailHeight: 180,

    caption: "",
    category: "birthday",
  },
  {
    src: require("../../Assets/Images/Gallery/birthday/birthday5.jpg"),
    thumbnail: require("../../Assets/Images/Gallery/birthday/birthday5.jpg"),
    thumbnailWidth: 230,
    thumbnailHeight: 180,

    caption: "",
    category: "birthday",
  },
  {
    src: require("../../Assets/Images/Gallery/christmas1.webp"),
    thumbnail: require("../../Assets/Images/Gallery/christmas1.webp"),
    thumbnailWidth: 357,
    thumbnailHeight: 180,

    caption: "",
    category: "christmas",
  },
  {
    src: require("../../Assets/Images/Gallery/christmas2.webp"),
    thumbnail: require("../../Assets/Images/Gallery/christmas2.webp"),
    thumbnailWidth: 270,
    thumbnailHeight: 125,

    caption: "",
    category: "christmas",
  },
  {
    src: require("../../Assets/Images/Gallery/christmas3.webp"),
    thumbnail: require("../../Assets/Images/Gallery/christmas3thumb.webp"),
    thumbnailWidth: 270,
    thumbnailHeight: 115,

    caption: "",
    category: "christmas",
  },
  {
    src: require("../../Assets/Images/Gallery/christmas4.webp"),
    thumbnail: require("../../Assets/Images/Gallery/christmas4thumb.webp"),
    thumbnailWidth: 270,
    thumbnailHeight: 130,

    caption: "",
    category: "christmas",
  },

  // {
  //     src: require('../../Assets/Images/Gallery/christmas5.webp'),
  //     thumbnail: require('../../Assets/Images/Gallery/christmas5.webp'),
  //     thumbnailWidth: 270,
  //     thumbnailHeight: 210,

  //     caption: "",
  //     category: "christmas"
  // },
];

const OurApproaches = [
  {
    img: commitment,
    title: "Commitment",
    desc: "We prioritize understanding your unique needs and delivering tailored solutions that exceed your expectations.",
  },
  {
    img: innovation,
    title: "Innovation",
    desc: "We explore new technologies to provide cutting-edge solutions that keep you ahead of the competition.",
  },
  {
    img: honesty,
    title: "Honesty",
    desc: "We believe in communication and ethical practices, ensuring that informed at every stage of the project.",
  },
  {
    img: passion,
    title: "Passion",
    desc: "Our team is dedicated to achieving excellence in every project, going the extra mile to ensure your success.",
  },
];

function About() {
  return (
    <>
      <NavigationBar />
      <section className="aboutpage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left">
                <h1>
                  Crafting Digital <span>Solutions</span>, Building Trust
                  <div className="sideSubTxt">About Us</div>
                </h1>
                <p>
                  We are IT experts who leads you in every step of the way, from
                  business model to implementation with smooth transition.
                </p>
              </div>
            </div>
            <div className="col-md-6 right">
              <img src={aboutPageImg} alt="" />
            </div>
          </div>
        </div>
        <img src={dot} className="dot" alt="" />
      </section>
      <section className="growWithUs">
        <div className="container">
          <div className="title">
            <h4 className="line_title">Grow With Us</h4>
            <h1>
              With the strength of right people, experience and desire, any
              business is destined for <span>growth.</span>
            </h1>
            <p>
              Vidhan Infotech PVT LTD is situated in Ahmedabad, India. Since our
              early twenties, we have assisted start-ups and businesses in
              bridging the gap between their vision and reality. We collaborate
              with clients to design, create, and integrate smart technology
              solutions. We provide world-class software development and
              consulting services for web, mobile, and cloud applications
              through our in-house team of professionals.
            </p>
            <p>
              Businesses increasingly rely on us for expansion, extending their
              existing resources with our Agile teams, and accelerating their
              path to success. With over 20+ workers and three global offices,
              we are at the forefront of technological innovation.
            </p>
          </div>
          <div className="row mission-vision">
            <div className="col-md-4 bt-0 bl-0">
              <div className="box">
                <h1>1.</h1>
                <h3>Our Mission</h3>
                <p>
                  Build on our technology competencies and create value for our
                  customers. We achieve this by focusing on the intersection of
                  our client’s emerging needs and the acceleration of business
                  and technological changes.
                </p>
              </div>
            </div>
            <div className="col-md-4 bt-0 bl-0 customeTopPadding">
              <div className="box text-center">
                <img src={mission} alt="" />
              </div>
            </div>
            <div className="col-md-4 bl-0 bb-0 br-0 top">
              <div className="box">
                <h1>2.</h1>
                <h3>Our Vision</h3>
                <p>
                  To become an integral part of our client’s success,
                  collaborating with them to achieve their strategic objectives
                  whilst creating long-lasting business value through the
                  delivery and management of their technology solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4 bt-0 bl-0 bb-0 customeBottomPadding">
              <div className="box text-center">
                <img src={vision} alt="" />
              </div>
            </div>
            <div className="col-md-4 bt-0 bl-0 bb-0">
              <div className="box">
                <h1>3.</h1>
                <h3>Our Values</h3>
                <p>
                  Our values are the guiding principles upon which we were
                  founded and how we strive to conduct our business on a daily
                  basis. Values establish our view of the world as we shape the
                  future. They determine how we treat each other.
                </p>
              </div>
            </div>
            <div className="col-md-4 border-0 bigDot">
              <div className="box text-center">
                <img src={bigDot} alt="" />
              </div>
            </div>
          </div>
        </div>
        <img src={growWithUs} className="growWithUsImg" alt="" />
      </section>
      <section className="counter">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="box">
                <h3>
                  Days of <span>Experience.</span>
                </h3>
                <p>
                  We bring extensive experience and expertise to every project,
                  ensuring top-quality solutions.
                </p>
                <h1>1825+</h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <h3>
                  Valuable Happy <span>Clients.</span>
                </h3>
                <p>
                  Happy clients who trust us to deliver innovative and efficient
                  IT solutions tailored to their unique needs.
                </p>
                <h1>80+</h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <h3>
                  Presence in <span>Countries.</span>
                </h3>
                <p>
                  Our global allowing us to leverage perspectives to provide
                  cutting-edge technology across markets.
                </p>
                <h1>50+</h1>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <h3>
                  Worldwide <span>Projects.</span>
                </h3>
                <p>
                  We have successfully completed 68+ worldwide projects,
                  showcasing our ability to manage IT
                  initiatives.
                </p>
                <h1>68+</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="approaches">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="title">
                <h4 className="line_title">Approaches</h4>
                <h1>
                  Our <span>Approaches</span>
                </h1>
                <p>
                  We pride ourselves on our diverse array of innovative
                  approaches tailored to meet the unique needs of each client.
                  From agile methodologies to robust project management
                  frameworks, we leverage cutting-edge technology and strategic
                  insights to deliver scalable, efficient solutions.
                </p>
                <img src={dot} className="dot" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mainBox">
                {OurApproaches?.map((data, i) => (
                  <div className="approachesBox" key={i}>
                    <div className="blackBack">
                      <h1>
                        {data?.title} <img src={data?.img} alt="" />
                      </h1>
                      <p>{data?.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <img src={dot} className="dotOuter" alt="" />
      </section>
      <section className="gallery">
        <div className="container">
          <div className="title">
            <h4 className="line_title">Work Culture</h4>
            <h1>
              Life & Culture @ <span>vidhan Infotech</span>
            </h1>
            <p>
              Uniting in work, laughter, celebration, shared experiences, and
              mutual growth, we forge an unbreakable bond!
            </p>
          </div>
        </div>
        <div className="carousel_card mb-4">
          <img src={galleryvector} className="galleryvector" alt="" />
          <Banner portfolio={portfolio} speed={30000} />
        </div>
        <div className="carousel_card_right">
          <Banner portfolio={portfolio1} speed={40000} />
        </div>
      </section>
      <NewFooter />
    </>
  );
}
export default React.memo(About);
