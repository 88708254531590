import React, { Suspense, lazy } from "react";
// import './Home.scss';
import "./Custome.scss";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
const Header = lazy(() => import("./Header"));
const OurClients = lazy(() => import("./OurClients"));
const AboutUs = lazy(() => import("./AboutUs"));
const Culture = lazy(() => import("./Culture"));
const WhatWeDoNew = lazy(() => import("./WhatWeDoNew"));
const HowWeEngage = lazy(() => import("./HowWeEngage"));
const Industry = lazy(() => import("./Industry"));
const ServicesNew = lazy(() => import("./ServicesNew"));
const Technologies = lazy(() => import("./Technologies"));
const Process = lazy(() => import("./Process"));
const Testimonial = lazy(() => import("./Testimonial"));
const Blog = lazy(() => import("./Blog"));
const GetinTouch = lazy(() => import("./GetinTouch"));

const Navigation = lazy(() => import("./Navigation"));
const Footer = lazy(() => import("./Footer"));
const Welcome = lazy(() => import("./Welcome"));
const WorkWith = lazy(() => import("./WorkWith"));
const OurServices = lazy(() => import("./OurServices"));
const GetinTouchOld = lazy(() => import("./GetinTouchOld"));
const HomeTechnology = lazy(() => import("./HomeTechnology"));
//const Process = lazy(() => import('./Process'));
const WhatWeDo = lazy(() => import("./WhatWeDo"));
const Whywe = lazy(() => import("./Whywe"));
const Howtowork = lazy(() => import("./Howtowork"));
const HomeBlog = lazy(() => import("./HomeBlog"));
const renderLoader = () => <p></p>;
function Home() {
  return (
    <Suspense fallback={renderLoader()}>
      <NavigationBar />
      <Header />
      <OurClients />
      <AboutUs />
      <Culture />
      <WhatWeDoNew />
      <HowWeEngage />
      <Industry />
      <ServicesNew />
      <Technologies />
      <Process />
      <Blog />
      <GetinTouch />
      <NewFooter />
    </Suspense>
  );
}
export default React.memo(Home);
