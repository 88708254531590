import React, { useEffect, useState, lazy } from "react";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import "./Career.scss";
// import 'boxicons';
import { useForm } from "react-hook-form";
import parse from "html-react-parser";
import experience from "../../Assets/Red_Theme/experience.png";
import description from "../../Assets/Red_Theme/description.png";

const Swal = lazy(() => import("sweetalert2"));
// const ReCaptchaV2 = lazy(() => import('react-google-recaptcha'));

function Career() {
  const [apply, setApply] = useState(false);
  const [resume, setResume] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [verified] = useState(false);
  const [error, setError] = useState("");
  const uploadPicture = (e) => {
    setResume(e.target.files[0]);
  };

  const JobListData = [
    {
      id: "1",
      sequence_id: "1",
      title: "Business Development Executive",
      image: "asp.webp",
      experience: "1+ Year (Minimum)",
      description:
        "We are seeking a dynamic and result-oriented Business Development Executive to join our team! As a Business Development Executive, you will be responsible for identifying new business opportunities, building strong client relationships, and driving the growth of our company. Your role will involve market research, strategic planning, and active engagement with clients to achieve business objectives.",
      job_Type: "Full-Time",
      location: "On-Site",
      industry: "Information Technology",
      skills:
        "<ul><li>Strong verbal and written communication skills.</li><li>Excellent interpersonal and negotiation abilities.</li><li>Knowledge of IT services such as software development, web design, mobile app development, etc.</li><li>Proficiency in CRM tools and Microsoft Office Suite.</li><li>Ability to work independently and meet deadlines.</li></ul>",
      other_skills:
        "<ul><li>Angular or React Experience is Advantage</li></ul>",
      responsibilities:
        "<ul><li>Lead Generation & Prospecting:</li><li>Identify potential clients through various online and offline channels like linked in etc.</li><li>Build and maintain a pipeline of qualified leads.</li><li>Ensuring a required level of performance</li><li>Writing test-backed server-side code</li><li>Analyzing requirements and designing new functionality</li><li>Supporting and fixing existing functionality</li></ul>",
      client_engagement:
        "<ul><li>Communicate with prospective clients to understand their requirements.</li><li>Present the company's services and solutions effectively.</li><li>Foster long-term relationships with clients by providing excellent service and support.</li></ul>",
      proposal_Writing_Negotiation:
        "<ul><li>Prepare detailed proposals, presentations, and cost estimates tailored to client needs.</li><li>Negotiate terms and close deals to meet sales targets.</li></ul>",
      market_Research_Analysis:
        "<ul><li>Analyze industry trends and competitor activities to identify business opportunities.</li><li>Provide input for improving services and market positioning.</li></ul>",
      collaboration_with_Teams:
        "<ul><li>Work closely with technical and project management teams to ensure successful delivery of client projects.</li><li>Act as a bridge between clients and internal teams to ensure seamless communication.</li></ul>",
      preferred:
        "<ul><li>Experience with platforms like LinkedIn, Upwork, or other freelancing portals.</li></ul>",
    },
    {
      id: "2",
      sequence_id: "2",
      title: "Flutter Developer",
      image: "laravel.webp",
      experience: "2 to 4 years",
      description:
        "We are looking for a skilled and passionate Flutter Developer to join our team! As a Flutter Developer, you will be responsible for building beautiful, performant, and feature-rich mobile applications using Flutter. You will work closely with designers, product managers, and other developers to bring cutting-edge ideas to life on both iOS and Android platforms.",
      skills:
        "<ul><li>Dart Programming Language</li><li>Flutter Framework</li><li>Mobile Development Basics</li><li>API Integration</li><li>State Management</li><li>UI/UX Design Principles</li><li>Testing and Debugging</li><li>Version Control Systems</li><li>Platform-Specific Skills</li><li>Performance Optimization</li><li>Problem-Solving</li><li>Team Collaboration</li><li>Learning Agility</li><li>IDEs</li><li>Database Management</li><li>Deployment</li><li>CI/CD Tools</li></ul>",
      other_skills: null,
      responsibilities:
        "<ul><li>Proficient in Flutter and Dart for building cross-platform mobile applications.</li><li>Strong expertise in integrating RESTful APIs, GraphQL, and WebSocket.</li><li>Skilled in local (SQLite, Hive) and cloud (Firebase Firestore) database management.</li><li>Advanced knowledge of state management solutions like GetX, Provider, and Bloc.</li><li>Proven experience in app performance optimisation for speed and memory efficiency.</li><li>Strong ability to manage and lead a development team effectively.</li><li>Excellent client communication skills for requirement gathering and updates.</li><li>Expertise in project planning, work allocation, and Agile methodologies.</li><li>Proficient in deploying apps to Google Play Store and Apple App Store.</li><li>Hands-on experience with Git and CI/CD pipelines for automated workflows.</li><li>Skilled in creating responsive, pixel-perfect UIs and animations.</li><li>Strong problem-solving skills and ability to deliver projects on time.</li></ul>",
    },
    {
      id: "3",
      sequence_id: "3",
      title: "MERN Stack Developer",
      image: "mern.webp",
      experience: "2 years min",
      description:
        "We are looking for a MERN Stack Developer responsible for managing back-end services and the interchange of data between the server and the users. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.",
      skills:
        "<ul><li>Node Js experience must required</li><li>M – MongoDB [ Database ]</li><li>E - Express JS [ Server side ] – node js web framework</li><li>R – React JS - a client side JavaScript framework</li><li>N - Node js [Back end technology] - the premier JavaScript web server.</li></ul>",
      other_skills: null,
      responsibilities: "",
    },
    {
      id: "4",
      sequence_id: "4",
      title: "Mean Stack Developer",
      image: "mean.webp",
      experience: "1 to 3 years",
      description:
        "We are looking for an Mean Stack Developer that will be able to develop and support existing applications. The main scope of work will include everything from back-end to client-side code, using optimal and efficient technologies, frameworks, and patterns. Your primary responsibilities will be to design and develop these applications. Therefore, it’s essential that you are skilled at problem solving, solution design, and high-quality coding",
      skills:
        "<ul><li>Node Js experience must required</li><li>M – MongoDB [ Database ]</li><li>E - Express JS [ Server side ] – node js web framework</li><li>A - Angular - a client side JavaScript framework – version 9+</li><li>N - Node js [Back end technology] - the premier JavaScript web server.</li></ul>",
      other_skills: null,
      responsibilities: "",
    },
    {
      id: "5",
      sequence_id: "5",
      title: "UI/UX Designer",
      image: "front-end.webp",
      experience: "3 years min",
      description:
        "We are seeking a talented and creative UI/UX Designer to join our team! As a UI/UX Designer, you will be responsible for designing intuitive, user-friendly, and visually appealing interfaces that enhance user experiences. You will collaborate closely with product managers, developers, and stakeholders to translate requirements into compelling designs and ensure seamless functionality across various platforms.",
      skills:
        "<ul><li>Design Principles</li><li>Wireframing & Prototyping</li><li>User Research</li><li>Information Architecture</li><li>Responsive Design</li><li>HTML/CSS Basics</li><li>Animation and Interaction</li></ul>",
      tools:
        "<ul><li>Design and Prototyping Tools - Figma, Adobe XD, Sketch, Axure RP, InVision.</li><li>Graphic Design Tools - Adobe Photoshop, Adobe Illustrator, CorelDRAW.</li><li>User Research Tools - Optimal Workshop, UsabilityHub, Hotjar.</li><li>Collaboration Tools - Miro, Zeplin, Slack.</li><li>3D & Animation Tools - Adobe After Effects, Principle, Lottie.</li><li>UI Libraries - Material Design, Human Interface Guidelines (HIG), and other design systems.</li></ul>",
      other_skills: null,
      responsibilities:
        "<ul><li>Design and deliver wireframes, prototypes, user flows, and mockups optimized for various devices and interfaces.</li><li>Create and maintain design systems, style guides, and UI components to ensure consistency across the product.</li><li>Collaborate with cross-functional teams to define user requirements and deliver engaging solutions.</li><li>Collaborate with cross-functional teams to define user requirements and deliver engaging solutions.</li><li>Stay updated with the latest design trends, tools, and technologies to enhance creativity and innovation.</li></ul>",
    }
  ];

  const uploadResume = (e) => {
    setResume(e.target.files[0]);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    setLoading(true);

    let fd = new FormData();
    fd.append("job", data.jobTitle);
    fd.append("name", data.name);
    fd.append("email", data.email);
    fd.append("mobile", data.mobile);
    fd.append("salary", data.currentSalary);
    fd.append("experience", data.totalExperience);
    fd.append("experience_in", data.experience);
    fd.append("resume", resume);
    fd.append("message", data.message);

    fetch(`http://viapi.vidhaninfotech.com/api/apply-now`, {
      method: "POST",
      body: fd,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((Apply) => {
        if (Apply.success === true) {
          setLoading(false);
          setError("");
          Swal.fire({
            title: "Your application has been sent successfully.",
            text: "We'll analyze your information and get back to you ...",
            icon: "success",
            type: "success",
          });

          setAlert("");
          setApply(false);
          e.target.reset();
        } else {
          setLoading(false);
          setError(Apply.message);
        }
      });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const Apply = (id) => {
    setApply(true);
  };

  const Close = () => {
    setApply(false);
  };

  const [viewmore, setViewmore] = useState("");
  const [toggle, setToggle] = useState(false);

  const ViewMore = (e) => {
    setToggle(!toggle);
    setViewmore(`career_box${e}`);
  };

  return (
    <>
      <NavigationBar />
      <section className="career">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="title mb-4">
                <h4 class="line_title">Team</h4>
                <h1>
                  Join <span>Our Team</span>
                </h1>
                <p>Lets Get Started With Your Career</p>
              </div>
            </div>
          </div>

          <div className="row">
            {JobListData.map((jobdata, i) => (
              <div className="col-md-6 col-lg-4">
                <div
                  className={`career_box ${
                    viewmore === `career_box${i}` && toggle
                      ? "view_more_active"
                      : ""
                  }`}
                  id={`career_box${i}`}
                >
                  <h2>{jobdata.title}</h2>
                  <div className="d-flex mb-4">
                    <img src={experience} className="img-fluid" alt="" />
                    <h5>
                      <span className="title">Experience</span>
                      {jobdata.experience}
                    </h5>
                  </div>
                  <div className="d-flex mb-4">
                    <img src={description} className="img-fluid" alt="" />
                    <h5>
                      <span className="title">Description</span>{" "}
                      {jobdata.description}
                    </h5>
                  </div>

                  <div className="viewmore_data">
                    {jobdata?.job_Type && (
                      <div className="d-flex gap-2 mb-4">
                        <h5>
                          <b>Job Type :</b>
                        </h5>
                        <p className="text-bg-dark m-0">{jobdata?.job_Type}</p>
                      </div>
                    )}
                    {jobdata?.location && (
                      <div className="d-flex gap-2 mb-4">
                        <h5>
                          <b>Location :</b>
                        </h5>
                        <p className="text-bg-dark m-0">{jobdata?.location}</p>
                      </div>
                    )}
                    {jobdata?.industry && (
                      <div className="d-flex gap-2 mb-4">
                        <h5>
                          <b>Industry :</b>
                        </h5>
                        <p className="text-bg-dark m-0">{jobdata?.industry}</p>
                      </div>
                    )}
                    {jobdata.skills && (
                      <ul className="responsibility">
                        <span className="title">Skills</span>
                        {parse(jobdata.skills)}
                      </ul>
                    )}

                    {jobdata.tools && (
                      <ul className="responsibility">
                        <span className="title">Tools</span>
                        {parse(jobdata.tools)}
                      </ul>
                    )}

                    {jobdata.responsibilities && (
                      <ul className="responsibility">
                        <span className="title">Responsibilities</span>
                        {parse(jobdata.responsibilities)}
                      </ul>
                    )}

                    {jobdata?.client_engagement && (
                      <ul className="responsibility">
                        <span className="title">Client Engagement</span>
                        {parse(jobdata?.client_engagement)}
                      </ul>
                    )}

                    {jobdata?.proposal_Writing_Negotiation && (
                      <ul className="responsibility">
                        <span className="title">
                          Proposal Writing Negotiation
                        </span>
                        {parse(jobdata?.proposal_Writing_Negotiation)}
                      </ul>
                    )}

                    {jobdata?.market_Research_Analysis && (
                      <ul className="responsibility">
                        <span className="title">Market Research Analysis</span>
                        {parse(jobdata?.market_Research_Analysis)}
                      </ul>
                    )}

                    {jobdata?.collaboration_with_Teams && (
                      <ul className="responsibility">
                        <span className="title">Collaboration with Teams</span>
                        {parse(jobdata?.collaboration_with_Teams)}
                      </ul>
                    )}

                    {jobdata?.preferred && (
                      <ul className="responsibility">
                        <span className="title">Preferred</span>
                        {parse(jobdata?.preferred)}
                      </ul>
                    )}
                  </div>
                  {/* <button
                    className="appy_now"
                    onClick={() => Apply(jobdata.id)}
                  >
                    Apply Now
                  </button> */}
                  <button className="view_more" onClick={() => ViewMore(i)}>
                    View More
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className={`apply_now_back ${apply ? "active" : ""}`}>
            <button className="close" onClick={Close}>
              +
            </button>
            <div className="apply_now">
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-md-12">
                    <select {...register("jobTitle", { required: true })}>
                      <option value="" selected disabled>
                        Select Job
                      </option>
                      {JobListData.map((jobdata, i) => (
                        <option id={jobdata.id} value={jobdata.id}>
                          {jobdata.title}
                        </option>
                      ))}
                    </select>
                    <span className="error_msg">
                      {errors.jobTitle?.type === "required" &&
                        "Job is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      {...register("name", { required: true })}
                      placeholder="Name"
                    />
                    <span className="error_msg">
                      {errors.name?.type === "required" && "Name is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="email"
                      {...register("email", { required: true })}
                      placeholder="Email"
                    />
                    <span className="error_msg">
                      {errors.email && "Email is required"}
                    </span>
                    {error.email && (
                      <span className="error_msg">{error.email}</span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <input
                      {...register("mobile", { required: true })}
                      placeholder="Mobile Number"
                    />
                    <span className="error_msg">
                      {errors.mobile && "Mobile number is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="number"
                      min="1"
                      {...register("currentSalary", { required: true })}
                      placeholder="Monthly Salary"
                    />
                    <span className="error_msg">
                      {errors.currentSalary && "Salary is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      min="1"
                      {...register("totalExperience", { required: true })}
                      placeholder="Total Experience"
                    />
                    <span className="error_msg">
                      {errors.totalExperience && "Total Experience is required"}
                    </span>
                    {error.experience && (
                      <span className="error_msg">{error.experience}</span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <select
                      onChange={uploadResume}
                      {...register("experience", { required: true })}
                    >
                      <option value="" selected disabled>
                        Select MM/YY
                      </option>
                      <option value="year">Year</option>
                      <option value="month">Month</option>
                    </select>
                    <span className="error_msg">
                      {errors.experience?.type === "required" &&
                        "Experience is required"}
                    </span>
                  </div>

                  <div className="col-md-6">
                    <input
                      type="file"
                      id="files"
                      onChange={uploadPicture}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    {/* <span className='error_msg'>{errors.resume && "Image is required"}</span> */}
                  </div>

                  <div className="col-md-6">
                    <input
                      {...register("message", { required: true })}
                      placeholder="Message"
                    />
                    <span className="error_msg">
                      {errors.message && "Message is required"}
                    </span>
                  </div>
                  {error && <span className="error_msg">{error}</span>}
                  <div className="col-md-12">
                    <span className="error_msg">{alert}</span>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      disabled={verified ? false : false}
                      className={loading ? "Loadingbtn" : ""}
                    >
                      {loading ? "Loading..." : "Send"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <NewFooter />
    </>
  );
}
export default React.memo(Career);
