import React, { memo } from 'react'

import { Link } from 'react-router-dom'
import Logowhite from '../../Assets/Images/vidhan-infotech-logo.jpg'
import IndFlag from '../../Assets/Images/ind-flag.webp'
import UsaFlag from '../../Assets/Images/us-flag.webp'
import newZealand from '../../Assets/Images/Flag_of_New_Zealand.svg.png'
import techmology1 from '../../Assets/Images/Technology/react.webp'
import angular from '../../Assets/Images/Technology/angular.webp'
import node from '../../Assets/Images/Technology/node.webp'
import python from '../../Assets/Images/Technology/python.webp'
import uiux from '../../Assets/Images/Technology/uiux.png'
import android from '../../Assets/Images/Technology/android.webp'
import IOS from '../../Assets/Images/Technology/ios.webp'
import laravel from '../../Assets/Images/Technology/laravel.webp'
import dotnet from '../../Assets/Images/Technology/mvc.webp'

function Footer() {
    return (
        <footer>
            {/* <div className="wave">
            </div> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer_links newsletter_inner">
                            <div className="india">
                                <div className="title"><img height="18px" width="27px" src={IndFlag} alt="India Flag" title="India Flag" /> India Office</div>
                                <div className="color-white">
                                    <p className="mb-2">A- 715 , The Capital, Science city road, Sola, Ahmedabad</p>
                                    <p className="mb-2"><a target="_blank" href="https://wa.me/8780838782"><box-icon type='logo' name='whatsapp'></box-icon> +91 8780 838 782</a></p>
                                    <p className="mb-2"><a target="_blank" href="https://wa.me/8780838782"><box-icon type='logo' name='whatsapp'></box-icon> +91 8160 455 387</a></p>
                                    <p><box-icon type='logo' name='gmail'></box-icon> info@vidhaninfotech.com</p>
                                </div>
                            </div>
                            <div className="USA">
                                <div className="title"><img height="18px" width="27px" src={UsaFlag} alt="USA Flag" title="USA Flag" /> USA Office</div>
                                <div className="color-white">
                                    <p className="mb-2">5189 Stewart Street, Milton, FL 32571, USA</p>
                                    <p><box-icon name='phone-call' type='solid' ></box-icon> +1 800-817-1408</p>
                                </div>
                            </div>
                            <div className="newZealand">
                                <div className="title"><img height="18px" width="27px" src={newZealand} alt="USA Flag" title="USA Flag" /> New Zealand Office</div>
                                <div className="color-white">
                                    <p className="mb-2">40 Leo Street, Glen Eden, Auckland 0602, New Zealand</p>
                                    <p><box-icon name='phone-call' type='solid' ></box-icon> +64 22899-9264</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="col-md-4">
                        <div className="footer_links">
                            <div className="title">Technologies We Provide</div>
                            <div className="inner_div_tech">
                                <img src={techmology1} className="technologies-img img-fluid" alt='' />
                                <img src={angular} alt="" className="technologies-img img-fluid" />
                                <img src={node} alt="" className="technologies-img img-fluid" />
                                <img src={python} alt="" className="technologies-img img-fluid" />
                                <img src={uiux} alt="" className="technologies-img img-fluid" />
                                <img src={android} alt="" className="technologies-img img-fluid" />
                                <img src={IOS} alt="" className="technologies-img img-fluid" />
                                <img src={dotnet} alt="" className="technologies-img img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer_links">
                            <div className="title">Quick Links</div>
                            <div className="inner_footer_links">
                                <Link to={{ pathname: "/home" }}>Home</Link>
                                <Link to={{ pathname: "/about" }}>About</Link>
                                <Link to={{ pathname: "/services" }}>Services</Link>
                                <Link to={{ pathname: "/portfolio" }}>Portfolio</Link>
                                <Link to={{ pathname: "/blog" }}>Blog</Link>
                                <Link to={{ pathname: "/career" }}>Career</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="footer_links">
                            <div className="title">Services</div>
                            <div className="inner_footer_links">
                                <Link to={{ pathname: "/viewservices/application-development" }}>App Development</Link>
                                <Link to={{ pathname: "/viewservices/custom-website-design" }}>Custom Web Design</Link>
                                <Link to={{ pathname: "/viewservices/software-development" }}>Software Development</Link>
                                <Link to={{ pathname: "/viewservices/enterprise-resource-planning" }}>Enterprise Resource Planning</Link>
                                <Link to={{ pathname: "/viewservices/e-commerce" }}>Ecommerce Services</Link>
                                <Link to={{ pathname: "/viewservices/quality-assurance" }}>Quality Assurance</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="footer_logo">
                            <div className="logo mb-4">
                                <Link to={{ pathname: "/", hash: "" }}><img height="100px" width="100px" src={Logowhite} alt="Vidhan Infotech Pvt. Ltd. White Logo" title="Vidhan Infotech Pvt. Ltd. White Logo" /></Link>
                            </div>
                            <div className="title text-center">Follow Us</div>
                            <div className="footer_div d-flex m-0">

                                <a href="https://www.facebook.com/vidhan.infotech.1/" target="_blank" className='social__link facebook'>
                                    <box-icon type="logo" name="facebook"></box-icon>
                                </a>
                                <a href="https://www.instagram.com/vidhaninfotech/" target="_blank" className='social__link insta'>
                                    <box-icon type="logo" name="instagram"></box-icon>
                                </a>
                                <a href="https://www.linkedin.com/company/vidhan-infotech-private-limited/" target="_blank" className='social__link linkedin'>
                                    <box-icon type="logo" name="linkedin"></box-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="copyright">
                        <div className="text-center">
                            <span><a href="https://www.vidhaninfotech.com/" target="_blank">&copy; Vidhan Infotech Private Limited.</a> 2022, All Rights Reserved.</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default React.memo(Footer)